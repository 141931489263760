import Visibility from '@src/elements/Visibility';
import classNames from 'classnames';
import React from 'react';
import { HeroSmallFragment } from '@src/graphql/gql-types';
import styles from './HeroSmall.module.scss';
import HeroContent from '@src/components/HeroContent';
import Heading from '@src/elements/Heading';
import { Column, Container, Row } from '@src/elements/Grid';
import MediaImage from '@src/components/MediaImage';
import { WordDictionary } from '@src/types/dictionary';
import { replaceStringWithDictionary } from '@src/utils/strings';
import NewsEventsTabs from '../NewsEventsTab/NewsEventsTab';

type HeroSmall = Partial<HeroSmallFragment> & {
  isCentered?: boolean;
  replacementDictionary?: WordDictionary;
  isCommunityNewsEvents?: boolean;
} & React.HTMLProps<HTMLElement>;

const HeroSmall: React.FC<HeroSmall> = ({
  heading,
  description,
  mediaItem,
  plain,
  isCentered = false,
  children,
  replacementDictionary,
  isCommunityNewsEvents = false
}) => {
  const headingText = replacementDictionary
    ? replaceStringWithDictionary(heading, replacementDictionary)
    : heading;

  const descriptionText = replacementDictionary
    ? replaceStringWithDictionary(description, replacementDictionary)
    : description;
  const mediaImage = mediaItem?.__typename === 'MediaImage' && mediaItem;

  return (
    <>
      {plain ? (
        <div className={styles.heroPlain}>
          <div
            className={classNames({
              [styles.heroPlainCentered]: !!isCentered
            })}
          >
            <div className={styles.plainWrapper}>
              <Heading priority={1} fontStyle="h1">
                {headingText}
              </Heading>
              {descriptionText && (
                <p className={styles.plainDescription}>{descriptionText}</p>
              )}
              {children && (
                <div className={styles.plainDescription}>{children}</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={classNames(styles.hero, {
              [styles.heroPlain]: plain
            })}
          >
            <div className={styles.backgroundMediaWrapper}>
              {mediaImage && (
                <div className={styles.backgroundMedia}>
                  <MediaImage {...mediaImage} priority />
                </div>
              )}
            </div>
            <div className={styles.heroBackground}>
              {/* Horizontal triangle for small devices */}
              <Visibility md={false}>
                <svg
                  className={styles.heroBackgroundTriangle}
                  viewBox="0 0 769 278"
                >
                  <polygon points="0 0, 0 278, 769 278" />
                </svg>
              </Visibility>
              {/* Vertical triangle for large devices */}
              <Visibility xs={false} md>
                <div className={styles.heroBackgroundBlock} />
                <svg
                  className={styles.heroBackgroundTriangle}
                  viewBox="0 0 301 568"
                >
                  <polygon points="0 0, 0 677, 301 568" />
                </svg>
                {isCommunityNewsEvents && (
                  <div id="color-bar" className={styles.colorBar} />
                )}
              </Visibility>
            </div>
            {/* Overlay content on larger devices */}
            <Visibility xs={false} md>
              <Container>
                <Row directionMd="row">
                  <Column md={3} xl={4}>
                    <div className={styles.heroContent}>
                      <HeroContent
                        heading={headingText || ''}
                        description={descriptionText || ''}
                      />
                    </div>
                    {isCommunityNewsEvents && <NewsEventsTabs />}
                  </Column>
                </Row>
              </Container>
            </Visibility>
          </div>
          <Visibility md={false}>
            <div>
              <div
                className={classNames(styles.mobileHeroContent, {
                  [styles.mobileTabContainer]: isCommunityNewsEvents
                })}
              >
                <HeroContent
                  heading={headingText || ''}
                  description={descriptionText || ''}
                />
                {isCommunityNewsEvents && <NewsEventsTabs isMobile />}
              </div>
            </div>
          </Visibility>
        </>
      )}
    </>
  );
};

export default HeroSmall;
