import algoliasearch from 'algoliasearch';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_API_KEY as string;

const client = algoliasearch(appId, apiKey);
const index = client.initIndex('levande_website_prod');

export interface SiteSearchResult {
  id: string;
  title?: string;
  content?: string;
  url?: string;
  isPdf?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const algoliaHitToSiteSearchResult = (hit: any): SiteSearchResult => ({
  id: hit.objectID,

  // TODO - keep an eye on results quality when we have more content. Might want to swap the order to 'lvl0 || lvl1'
  title: hit.hierarchy?.lvl1 || hit.hierarchy?.lvl0 || hit.title, // hit.title is for pdf records

  url: hit.isPdf ? hit.url : hit.url_without_anchor,
  isPdf: hit.isPdf,
  content:
    hit.content || // should really not fall back to any of the below, but keeping them just in case
    hit.hierarchy?.lvl0 ||
    hit.hierarchy?.lvl1 ||
    hit.hierarchy?.lvl2 ||
    hit.hierarchy?.lvl3 ||
    hit.hierarchy?.lvl4 ||
    hit.hierarchy?.lvl5
});

interface SearchSiteContentOpts {
  page?: number;
  hitsPerPage?: number;
}

const hasOpts = (opts?: SearchSiteContentOpts) => {
  return (
    opts &&
    Object.values(opts)?.filter((v) => {
      return v || v === 0;
    })?.length > 0
  );
};

export const searchSiteContent = async (
  searchString: string,
  opts?: SearchSiteContentOpts
) => {
  const response = hasOpts(opts)
    ? await index.search(searchString, {
        ...(opts?.page && { page: opts.page }),
        ...(opts?.hitsPerPage && { hitsPerPage: opts.hitsPerPage })
      })
    : await index.search(searchString);
  return {
    hits: response?.hits?.map(algoliaHitToSiteSearchResult) || [],
    pages: response.nbPages,
    hitsPerPage: response.hitsPerPage,
    nbHits: response.nbHits
  };
};
