import React, { useState } from 'react';
import styles from './SearchResults.module.scss';
import useSearch from '@src/hooks/useSearch';
import { Column, Container, Row } from '@src/elements/Grid';
import Pagination from '@src/components/Pagination';
import LinkWrapper from '@src/components/LinkWrapper';
import { getPageUrlWithoutBaseURL } from '@src/utils/url';
import PdfSVG from '@src/svgs/PdfSVG';
import HeroSmall from '@src/components/HeroSmall';
import { COLOR_BLUE } from '@src/types/colors';
import { PATHS } from '@src/constants/site';

interface SearchResults {
  searchString?: string;
}

const SearchResults: React.FC<SearchResults> = ({ searchString = '' }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { results, searchLoading, searchError, pages, totalNumResults } =
    useSearch(searchString, {
      page: currentPage,
      hitsPerPage: 20
    });
  if (searchError) {
    console.log(searchError);
  }

  return (
    <>
      <HeroSmall
        heading={`${totalNumResults} Results found for "${searchString}"`}
        plain
        isCentered
      >
        <p>
          Or do you want to{' '}
          <LinkWrapper
            className={styles.heroLinkWrapper}
            href={PATHS.FIND_YOUR_COMMUNITY}
          >
            find a community
          </LinkWrapper>{' '}
          near you?
        </p>
      </HeroSmall>
      <Container>
        <div className={styles.narrowContent}>
          {searchLoading && (
            <Row>
              <Column>
                <p>Loading Results</p>
              </Column>
            </Row>
          )}
          {searchError && (
            <Row>
              <Column>
                An unexpected error has occurred. Please try again
              </Column>
            </Row>
          )}
          {results?.map((result) => (
            <div key={result.id} className={styles.resultItem}>
              <Row>
                <Column>
                  <Row>
                    <Column xs={3} md={7} lg={7} xl={10}>
                      <LinkWrapper href={result.url}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: result.title as string
                          }}
                        />
                      </LinkWrapper>

                      <LinkWrapper
                        className={styles.resultItemLinkWrapper}
                        href={result.url}
                      >
                        {getPageUrlWithoutBaseURL(result.url)}
                      </LinkWrapper>

                      {!result.isPdf && (
                        <p
                          className={styles.excerpt}
                          dangerouslySetInnerHTML={{
                            __html: result.content as string
                          }}
                        />
                      )}
                    </Column>
                    <Column xs={1} md={1} lg={1} xl={2}>
                      <div className={styles.pdfSvgWrapper}>
                        {result.isPdf && <PdfSVG color={COLOR_BLUE} />}
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </div>
          ))}
          <br /> <br />
          {pages > 1 && (
            <div className={styles.paginationWrapper}>
              <Pagination
                numPages={pages}
                onPageChanged={(newPage) => {
                  setCurrentPage(newPage);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default SearchResults;
