import { Column, Container, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import Visibility from '@src/elements/Visibility';
import { CtaFragment, GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { COLOR_SLATE } from '@src/types/colors';
import classNames from 'classnames';
import React from 'react';
import styles from './GlobalImpactPanel.module.scss';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { getArrayOfCTAs } from '@src/utils/helpers';
import CTAButton from '@src/components/Button/CTAButton';

type GlobalImpactPanel = GlobalSettingItemFragment;

const GlobalImpactPanel: React.FC<GlobalImpactPanel> = ({
  value,
  richText,
  callToActionCollection
}) => {
  const ctasArray = getArrayOfCTAs(
    callToActionCollection?.items as CtaFragment[]
  );

  return (
    <div className={styles.impactPanelContainer}>
      <Container containerWidth="fluid">
        <div className={styles.contentContainer}>
          <Row>
            <Column
              alignXs="center"
              justifyXs="center"
              xs={4}
              xl={6}
              offsetXl={3}
            >
              <Heading priority={2} fontStyle={'h2'}>
                {value}
              </Heading>
            </Column>
          </Row>
          <Row>
            <Column alignXs="center" justifyXs="center">
              {richText && (
                <RichTextRender
                  bodyContent={richText}
                  pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                />
              )}

              {ctasArray?.map((cta, index) => (
                <CTAButton
                  cta={cta}
                  key={index}
                  color={COLOR_SLATE}
                  label={cta.label || ''}
                />
              ))}
            </Column>
          </Row>
        </div>
      </Container>

      <div className={styles.triangleBackground}>
        {/* Double triangle background desktop */}
        <div className={styles.triangleContainerDesktop}>
          <Visibility xs={false} md>
            <div className={styles.backgroundTriangleTop}>
              <svg viewBox="0 0 769 278">
                <polygon points="0 0, 0 278, 769 278" />
              </svg>
            </div>
            <div
              className={classNames(
                styles.backgroundTriangleTop,
                styles.backgroundTriangleBottom
              )}
            >
              <svg viewBox="0 0 769 278">
                <polygon points="0 0, 0 278, 769 278" />
              </svg>
            </div>
            <div className={styles.halfWidthOrangeLightBackground} />
            <div className={styles.halfWidthOrangeBackground} />
          </Visibility>
        </div>

        {/* Double triangle background mobile */}
        <Visibility md={false}>
          <svg className={styles.backgroundTriangleLeft} viewBox="0 0 301 677">
            <polygon points="0 0, 0 677, 301 677" />
          </svg>
          <svg
            className={classNames(
              styles.backgroundTriangleLeft,
              styles.backgroundTriangleRight
            )}
            viewBox="0 0 301 677"
          >
            <polygon points="0 0, 0 677, 301 677" />
          </svg>
        </Visibility>
      </div>
    </div>
  );
};

export default GlobalImpactPanel;
