import { ListingType } from '@src/types/newsAndEvents';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './NewsEventsTab.module.scss';
import { useRouter } from 'next/router';
import { PATHS } from '@src/constants/site';
import NextLink from 'next/link';
interface NewsEventsTab {
  isMobile?: boolean;
}

const NewsEventsTab: React.FC<NewsEventsTab> = ({ isMobile = false }) => {
  const router = useRouter();
  const PATH_NAME = `/${PATHS.COMMUNITY}/${router.query.slug_community_detail}/${PATHS.COMMUNITY_NEWS_AND_EVENTS}`;

  const selectedTabQueryParam =
    router.query.selectedTab === 'events' ? router.query.selectedTab : 'news';

  const [selectedTab, setSelectedTab] = useState(
    selectedTabQueryParam as string
  );

  // makes sure that the selected tab query param in the url (eg. .../news-and-events/?selectedTab=news)
  // and the selected tab are the same
  useEffect(() => {
    if (selectedTabQueryParam !== selectedTab) {
      setSelectedTab(selectedTabQueryParam as string);
    }
  }, [router, selectedTabQueryParam]);

  const handleTabSelect = (heading: ListingType) => {
    if (selectedTab !== heading) {
      setSelectedTab(heading);
    }
  };

  return (
    <div className={isMobile ? styles.mobileTabContainer : styles.tabContainer}>
      <NextLink
        data-testid="communityNewsTab"
        href={{ pathname: PATH_NAME, query: { selectedTab: 'news' } }}
        passHref
        id="news-tab"
        className={classNames(styles.newsEventsTab, {
          [selectedTab === 'news' ? styles.selectedTab : styles.unselectedTab]:
            true
        })}
        onClick={() => {
          handleTabSelect('news');
        }}
      >
        <p>News</p>
      </NextLink>
      <div className={styles.spacer} />
      <NextLink
        data-testid="communityEventTab"
        href={{ pathname: PATH_NAME, query: { selectedTab: 'events' } }}
        passHref
        id="events-tab"
        className={classNames(styles.newsEventsTab, {
          [selectedTab === 'events'
            ? styles.selectedTab
            : styles.unselectedTab]: true
        })}
        onClick={() => {
          handleTabSelect('events');
        }}
      >
        <p>Events</p>
      </NextLink>
    </div>
  );
};
export default NewsEventsTab;
