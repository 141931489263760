import React from 'react';
import {
  GlobalSettingItemFragment,
  MediaImageFragment,
  PageCommunityDetailFragment
} from '@src/graphql/gql-types';
import Layout from '@src/components/Layout';
import SEO from '@src/components/SEO';
import HeroLarge from '@src/components/HeroLarge';
import HeroSmall from '@src/components/HeroSmall';
import Discover from '@src/components/Discover';
import { getPageUrlPath } from '@src/utils/url';
import { ContentfulPageHyperlinkFragments } from '@src/types/contentful';
import {
  generateMetaTitle,
  getBreadcrumbs,
  getFooterNavItems,
  getOgImage,
  isNationalOrCommunityLandingPage
} from '@src/utils/helpers';
import { getEnquiryFormValues } from '@src/utils/form';
import {
  getFormEnquiryDisclaimerEntry,
  getGlobalContactPhoneNumberEntry,
  getGlobalCTAPanelEntry,
  getGlobalImpactPanelEntry,
  getNationalContactUsHeadOfficeEntry,
  getSiteWideAlertBannerEntry
} from '@src/utils/globalSettings';
import ContentBlocks from '@src/components/ContentBlocks';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import GlobalCTAPanel from '@src/components/GlobalCTAPanel';
import GlobalImpactPanel from '@src/components/GlobalImpactPanel';
import { NationalStandardPageProps } from '@src/types/page';
import { createReplaceDictionary } from '@src/utils/strings';
import SearchResultsContent from '@src/components/SearchResults';
import NotFound from '@src/components/NotFound';
import HtmlComment from '@src/components/HtmlComment';
import IntroText from '@src/components/IntroText';
import GenericText from '@src/components/GenericText';
import { getLatestDate } from '@src/utils/date';
import { SITE_NAME } from '@src/constants/site';

type PageNationalStandardTemplate = NationalStandardPageProps & {
  searchString?: string;
  notFound?: boolean;
};

const PageNationalStandardTemplate: React.FC<PageNationalStandardTemplate> = ({
  globalSettingsPayload,
  nationalBasePagePayload,
  pagePayload,
  pageContentBlocks,
  searchString,
  notFound
}) => {
  const globalSettingItems = globalSettingsPayload?.globalSettings
    ?.items as GlobalSettingItemFragment[];
  const pageData = pagePayload?.page?.items?.[0];
  const pagePathData = pagePayload?.pagePath
    ?.items?.[0] as ContentfulPageHyperlinkFragments;
  const { communities, footerNav, legalLinks, primaryNav, socialLinks } =
    nationalBasePagePayload || {};

  const communityItems = communities?.items as PageCommunityDetailFragment[];
  const title = pageData?.title;
  const slug = pageData?.slug;
  const seoTitle = pageData?.seoTitle;
  const seoDescription = pageData?.seoDescription;
  const noFollow = pageData?.noFollow;
  const noIndex = pageData?.noIndex;
  const hero = pageData?.hero;
  const introText = pageData?.introText;
  const discoverBlock = pageData?.discoverBlock;
  const displayGlobalImpactPanel = pageData?.displayGlobalImpactPanel;
  const displayGlobalCtaPanel = pageData?.displayGlobalCtaPanel;
  const disclaimer = pageData?.disclaimer;
  const publishedAt = pageData?.sys?.publishedAt;

  const metaTitle = seoTitle ?? generateMetaTitle([title, SITE_NAME]);
  const isHomePage = slug === '/';
  const heroLarge = hero && hero?.__typename === 'HeroLarge';
  const heroSmall = hero && hero?.__typename === 'HeroSmall';
  const pageURLPath = getPageUrlPath(pagePathData);
  const breadcrumbs = getBreadcrumbs(pagePathData);

  const globalImpactPanel = getGlobalImpactPanelEntry(globalSettingItems);
  const globalCTAPanel = getGlobalCTAPanelEntry(globalSettingItems);
  const alertBanner = getSiteWideAlertBannerEntry(globalSettingItems);
  const formNationalContactUsHeadOfficeContent =
    getNationalContactUsHeadOfficeEntry(globalSettingItems);
  const formDisclaimerRichText =
    getFormEnquiryDisclaimerEntry(globalSettingItems);

  const replacementDictionary = createReplaceDictionary(
    globalSettingItems,
    getLatestDate(hero?.sys?.publishedAt, publishedAt)
  );

  const dynamicFormValues = getEnquiryFormValues(communityItems);
  const metaOgImage = getOgImage(hero?.mediaItem as MediaImageFragment);

  const footerNavItems = getFooterNavItems(
    primaryNav,
    footerNav,
    socialLinks,
    legalLinks,
    breadcrumbs
  );

  return (
    <Layout
      alertBannerData={alertBanner}
      primaryNav={primaryNav?.items[0]?.navigationElementsCollection?.items}
      footerNav={footerNavItems}
      showBreadCrumbs={
        !isNationalOrCommunityLandingPage(pagePayload?.page?.items[0])
      }
      dynamicFormValues={dynamicFormValues}
      formDisclaimerRichText={formDisclaimerRichText}
      headOfficePhoneNumber={
        getGlobalContactPhoneNumberEntry(globalSettingItems)?.value
      }
      theme="defaultTheme"
    >
      <SEO
        metaTitle={metaTitle}
        metaDesc={seoDescription || ''}
        metaPageUrl={pageURLPath}
        metaOgImage={metaOgImage?.url}
        metaOgImageAlt={metaOgImage?.alt}
        noIndex={noIndex}
        noFollow={noFollow}
      />

      <HtmlComment comment="PageNationalStandard Template" />

      {/* components that oveflow the grid */}
      {heroLarge && (
        <HeroLarge
          {...hero}
          slate={isHomePage}
          replacementDictionary={replacementDictionary}
        />
      )}
      {heroSmall && (
        <HeroSmall {...hero} replacementDictionary={replacementDictionary} />
      )}

      {!heroLarge && !heroSmall && title && (
        <HeroSmall
          heading={title}
          plain
          replacementDictionary={replacementDictionary}
        />
      )}

      {introText && (
        <IntroText
          introText={introText}
          replacementDictionary={replacementDictionary}
        />
      )}

      {pageContentBlocks && (
        <ContentBlocks
          items={pageContentBlocks}
          pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD}
          staticBlockData={{
            nationalContactForm: {
              disclaimerRichText: formDisclaimerRichText,
              headOfficeContent: formNationalContactUsHeadOfficeContent
            },
            communities: communityItems || undefined,
            primaryNav
          }}
          sharedBuildData={{ replacementDictionary }}
        />
      )}

      {displayGlobalImpactPanel && globalImpactPanel && (
        <GlobalImpactPanel {...globalImpactPanel} />
      )}

      {displayGlobalCtaPanel && globalCTAPanel && (
        <GlobalCTAPanel
          dynamicFormValues={dynamicFormValues}
          formDisclaimerRichText={formDisclaimerRichText}
          {...globalCTAPanel}
        />
      )}

      {discoverBlock && <Discover {...discoverBlock} />}

      {disclaimer && disclaimer?.bodyContent && (
        <GenericText
          disclaimer
          {...disclaimer}
          pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
          sharedBuildData={{ replacementDictionary }}
        />
      )}

      {/* render search results */}
      {searchString && <SearchResultsContent searchString={searchString} />}

      {/* render 404 not found */}
      {notFound && <NotFound />}
    </Layout>
  );
};

export default PageNationalStandardTemplate;
