import Visibility from '@src/elements/Visibility';
import classNames from 'classnames';
import React, { useMemo, useState, useRef } from 'react';
import {
  CtaFragment,
  GlobalSettingItemFragment,
  HeroLargeFragment,
  Maybe
} from '@src/graphql/gql-types';
import styles from './HeroLarge.module.scss';
import HeroContent from '@src/components/HeroContent';
import { Column, Container, Row } from '@src/elements/Grid';
import ButtonStandard from '@src/components/Button/ButtonStandard';
import { SVG_ARROW_DOWN, SVG_PAUSE, SVG_PLAY } from '@src/types/svg';
import ReactPlayer from 'react-player';
import Icon from '@src/elements/Icon';
import { COLOR_SECONDARY, COLOR_SLATE } from '@src/types/colors';
import MediaImage from '@src/components/MediaImage';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { autoGood } from '@cloudinary/url-gen/qualifiers/quality';
import { cloudinaryClient } from '@src/services/cloudinary';
import { WordDictionary } from '@src/types/dictionary';
import { replaceStringWithDictionary } from '@src/utils/strings';
import { useWindow } from '@src/hooks/useWindow';

type HeroLarge = HeroLargeFragment & {
  slate?: boolean;
  replacementDictionary?: WordDictionary;
  displayTourBookingButton?: boolean;
  isCommunity?: boolean;
  formDisclaimerRichText?: GlobalSettingItemFragment;
  headOfficePhoneNumber?: Maybe<string>;
};

const videoConfig = {
  file: {
    attributes: {
      style: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
      }
    }
  }
};

const PlayButton = ({
  progress,
  playing,
  setPlaying
}: {
  progress: number;
  playing: boolean;
  setPlaying: (playing: boolean) => void;
}) => {
  const { document } = useWindow();
  const theme = document
    ?.querySelector("div[class^='theme-']")
    ?.className.split('-')[1];

  let themeColors = {
    primary: 'transparent',
    secondary: 'transparent'
  };

  switch (theme) {
    case 'blueTheme':
      themeColors = {
        primary: styles.colorblue,
        secondary: styles.colorlightblue
      };
      break;
    case 'orangeTheme':
      themeColors = {
        primary: styles.colororange,
        secondary: styles.colorlightorange
      };
      break;
    case 'lilacTheme':
      themeColors = {
        primary: styles.colorlilac,
        secondary: styles.colorlightlilac
      };
      break;
    case 'slateTheme':
      themeColors = {
        primary: styles.colorslate,
        secondary: styles.colorlightslate
      };
      break;
    default:
      themeColors = {
        primary: styles.colorlime,
        secondary: styles.colorlightlime
      };
      break;
  }

  return (
    <div className={styles.playButton} onClick={() => setPlaying(!playing)}>
      <div
        className={styles.progressRing}
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        style={{
          background: `conic-gradient(${themeColors.primary} 0deg ${(
            progress * 360
          ).toFixed(1)}deg, ${themeColors.secondary} ${(progress * 360).toFixed(
            1
          )}deg 360deg)`
        }}
      ></div>
      <div className={styles.playIconButtonWrapper}>
        <div className={styles.playIconWrapper}>
          {playing ? (
            <Icon icon={SVG_PAUSE} color={COLOR_SLATE} />
          ) : (
            <div className={styles.playIconPad}>
              <Icon icon={SVG_PLAY} color={COLOR_SLATE} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HeroLarge: React.FC<HeroLarge> = ({
  heading,
  description,
  mediaItem,
  slate,
  replacementDictionary,
  primaryCta,
  isCommunity,
  formDisclaimerRichText,
  displayTourBookingButton,
  headOfficePhoneNumber
}) => {
  const { document } = useWindow();
  const scrollRef = useRef<HTMLSpanElement | null>(null);
  const mediaImage = mediaItem?.__typename === 'MediaImage' && mediaItem;
  const mediaVideo = mediaItem?.__typename === 'MediaVideo' && mediaItem;
  const [videoProgress, setVideoProgress] = useState(0);
  const [playing, setPlaying] = useState(true);

  const headingText = replacementDictionary
    ? replaceStringWithDictionary(heading, replacementDictionary)
    : heading;

  const descriptionText = replacementDictionary
    ? replaceStringWithDictionary(description, replacementDictionary)
    : description;

  const scrollToRef = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // prevent play button progress ring from rerendering scroll down button's arrow bounce animation
  const scrollDown = useMemo(
    () => (
      <ButtonStandard
        onClick={scrollToRef}
        color={COLOR_SECONDARY}
        icon={SVG_ARROW_DOWN}
        iconPosition="right"
        isIconBouncing
      >
        Scroll Down
      </ButtonStandard>
    ),
    []
  );

  const updateVideoProgress = ({ played }: { played: number }) => {
    setVideoProgress(played);
  };

  let video;
  if (mediaVideo) {
    video = cloudinaryClient.video(
      mediaVideo?.cloudinaryVideoSource?.[0].public_id
    );
    video.resize(fill().height(720));
  }

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.backgroundMediaWrapper}>
          <div className={styles.backgroundMedia}>
            {mediaImage && <MediaImage {...mediaImage} priority />}
            {document && mediaVideo && (
              <ReactPlayer
                url={video?.delivery(format('mp4')).quality(autoGood()).toURL()}
                width="100%"
                height="100%"
                config={videoConfig}
                progressInterval={33}
                onProgress={updateVideoProgress}
                playing={playing}
                playsinline
                loop
                volume={0}
                muted
              />
            )}
          </div>
        </div>
        <div className={styles.heroBackground}>
          {/* Horizontal triangle for small devices */}
          <Visibility md={false}>
            <svg
              className={classNames({
                [styles.heroBackgroundTriangleThemed]: !slate,
                [styles.heroBackgroundTriangleSlate]: slate
              })}
              viewBox="0 0 769 278"
            >
              <polygon points="0 0, 0 278, 769 278" />
            </svg>
            <div className={styles.mobileButtonWrapper}>
              {scrollDown}
              {mediaVideo && (
                <PlayButton
                  progress={videoProgress}
                  playing={playing}
                  setPlaying={setPlaying}
                />
              )}
            </div>
          </Visibility>
          {/* Vertical triangle for large devices */}
          <Visibility xs={false} md>
            <div
              className={classNames({
                [styles.heroBackgroundBlockThemed]: !slate,
                [styles.heroBackgroundBlockSlate]: slate
              })}
            />
            <svg
              className={classNames({
                [styles.heroBackgroundTriangleThemed]: !slate,
                [styles.heroBackgroundTriangleSlate]: slate
              })}
              viewBox="0 0 301 677"
            >
              <polygon points="0 0, 0 677, 301 677" />
            </svg>
          </Visibility>
        </div>
        {/* Overlay content on larger devices */}
        <Visibility xs={false} md>
          <Container>
            <Row directionMd="row">
              <Column md={3} xl={4}>
                <div className={styles.heroContent}>
                  <HeroContent
                    cta={primaryCta as CtaFragment}
                    heading={headingText || ''}
                    description={descriptionText || ''}
                    slate={slate}
                    displayTourBookingButton={displayTourBookingButton}
                    isCommunity={isCommunity}
                    formDisclaimerRichText={
                      formDisclaimerRichText as GlobalSettingItemFragment
                    }
                    headOfficePhoneNumber={headOfficePhoneNumber as string}
                  />
                </div>
              </Column>
              <Column
                alignMd="flex-end"
                justifyMd="flex-end"
                md={2}
                xl={2}
                offsetMd={3}
                offsetXl={6}
              >
                <div className={styles.buttonWrapper}>
                  {scrollDown}
                  {mediaVideo && (
                    <PlayButton
                      progress={videoProgress}
                      playing={playing}
                      setPlaying={setPlaying}
                    />
                  )}
                </div>
              </Column>
            </Row>
          </Container>
        </Visibility>
      </div>

      <Visibility md={false}>
        <div
          className={classNames({
            [styles.mobileHeroWrapperThemed]: !slate,
            [styles.mobileHeroWrapperSlate]: slate
          })}
        >
          <Container>
            <div className={styles.mobileHeroContent}>
              <HeroContent
                cta={primaryCta as CtaFragment}
                heading={headingText || ''}
                description={descriptionText || ''}
                slate={slate}
                displayTourBookingButton={displayTourBookingButton}
                isCommunity={isCommunity}
                formDisclaimerRichText={
                  formDisclaimerRichText as GlobalSettingItemFragment
                }
                headOfficePhoneNumber={headOfficePhoneNumber as string}
              />
            </div>
          </Container>
        </div>
      </Visibility>

      <span ref={scrollRef} className={styles.scrollRef} />
    </>
  );
};

export default HeroLarge;
