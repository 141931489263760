import Content from '@src/elements/Content';
import { Container } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { BangSVG } from '@src/svgs';
import { COLOR_SLATE } from '@src/types/colors';
import React from 'react';
import LinkWrapper from '@src/components/LinkWrapper';
import styles from './NotFound.module.scss';
import { useRouter } from 'next/router';
import {
  PATHS,
  SELECT_COMMUNITY_EVENT_TAB_QUERY_PARAM
} from '@src/constants/site';
import Icon from '@src/elements/Icon';
import { SVG_CALENDAR } from '@src/types/svg';

const NotFound = () => {
  const router = useRouter();
  const isEvent404 = router.asPath.includes(
    `${PATHS.COMMUNITY_NEWS_AND_EVENTS}/${PATHS.COMMUNITY_EVENT}`
  );

  const communityEventRoute = isEvent404
    ? router.asPath.split('/event/')[0] +
      '/' +
      SELECT_COMMUNITY_EVENT_TAB_QUERY_PARAM
    : '';

  const EventMessage = () => (
    <p className={styles.notFoundCopy}>
      Click <LinkWrapper href={communityEventRoute}>here</LinkWrapper> to go
      back to the events for this community.
    </p>
  );

  const DefaultMessage = () => (
    <p className={styles.notFoundCopy}>
      Sorry &ndash; looks like the page you&lsquo;re after has been changed or
      removed. Try{' '}
      <LinkWrapper href="/find-your-community">searching</LinkWrapper> to
      quickly match the location or property of your needs.
    </p>
  );

  return (
    <Container>
      <div className={styles.notFound} data-testid="pageNotFound">
        <div className={styles.notFoundHero}>
          {isEvent404 ? (
            <Icon icon={SVG_CALENDAR} />
          ) : (
            <BangSVG color={COLOR_SLATE} />
          )}
        </div>

        <Heading fontStyle="h1" priority={1}>
          {isEvent404 ? 'Event has concluded' : 'Page not found'}
        </Heading>

        <Content bodyCopyVariant="largeRegular">
          {isEvent404 ? EventMessage() : DefaultMessage()}
        </Content>
      </div>
    </Container>
  );
};

export default NotFound;
