import { searchSiteContent, SiteSearchResult } from '@src/services/algolia';
// TODO - currently in order to see the SearchResults components in storybook, need to swap the commented import with the normal one
// need to configure storybook to automatically import the right one
// import {
//   searchSiteContent,
//   SiteSearchResult
// } from '@src/stories/mocks/algolia';
import { useEffect, useState } from 'react';

interface SearchOpts {
  page?: number;
  hitsPerPage?: number;
}

const useSearch = (searchString: string, opts?: SearchOpts) => {
  const { page, hitsPerPage } = opts || {};
  const [results, setResults] = useState<SiteSearchResult[]>([]);
  const [resultsPerPage, setResultsPerPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [totalNumResults, setTotalNumResults] = useState(0);
  const [searchError, setSearchError] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setSearchLoading(true);
        const results = await searchSiteContent(searchString || '', {
          page,
          hitsPerPage
        });
        setResults(results?.hits || []);
        setResultsPerPage(results?.hitsPerPage || 0);
        setPages(results?.pages || 0);
        setTotalNumResults(results?.nbHits || 0);
        setSearchLoading(false);
        setSearchError(undefined);
      } catch (error: any) {
        setSearchError(error);
        setSearchLoading(false);
      }
    })();
  }, [searchString, page, hitsPerPage]);

  return {
    results,
    searchError,
    searchLoading,
    resultsPerPage,
    pages,
    totalNumResults
  };
};

export default useSearch;
